import React from 'react'
import PaintingImage from './PaintingImage'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import LazyLoad from 'react-lazyload'

import { media } from '../themes/index'
import { Default, Desktop, Tablet, Phone } from '../components/Responsive'

import ImageFit from './ImageFit'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
`

const PaintingTitle = styled.div`
  font-size: 0.8rem;
  line-height: 1rem;
  color: #868686;
  margin-bottom: 5px;
`

const LabelStyle = css`
  color: #c5c5c5;
  font-size: 0.7rem;
  line-height: 1rem;
`

const PaintingDescription = styled.div`
  ${LabelStyle};
`

const PaintingSizes = styled.div`
  ${LabelStyle};
`

const LinkPainting = styled(Link)`
  text-decoration: none;
  width: 100%;
`

const PaintImageFit = styled(ImageFit)`
  margin: 20px auto;
`
export const getGridWidth = (grid) => {
  switch (grid) {
    case 'full':
      return '100%'
    case 'half':
      return '50%'

    default:
      return '33.33%'
  }
}

const PaintingPlaceholder = styled.div`
  /* border: 1px solid red; */
  /* width: ${({ sizes }) => (sizes.width > 150 ? '100%' : '33.33%')}; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ grid }) => getGridWidth(grid)};
  min-height: 400px;
  /* padding: 0px 0.5rem; */
  margin-bottom: 20px;

  a {
    display: flex;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    background-color: #f8f8f8;
    overflow: hidden;
    text-indent: -9000px;
  }

  /* ${media.tablet`
    width: ${({ sizes }) => (sizes.width > 150 ? '100%' : '50%')};
  `} */

  ${media.phone`
    width: 100%;
    min-height: auto;
  `}
`

const Painting = ({
  id,
  windowWidth,
  className,
  title,
  no_frame_shadow,
  image,
  composition,
  sizes,
  realSizes,
  sizes_description,
  slug,
  grid,
  year,
}) => {
  const paintingImage = (
    <PaintingImage
      no_frame_shadow={no_frame_shadow}
      windowWidth={windowWidth}
      imageSizes={image}
      sizes={sizes}
      realSizes={realSizes}
    />
  )

  return (
    <Container className={className}>
      <LazyLoad
        key={id}
        height={400}
        once={true}
        placeholder={
          <PaintingPlaceholder sizes={sizes} grid={grid}>
            <Link to={`/painting/${slug}`} name={slug}>
              {title}
            </Link>
          </PaintingPlaceholder>
        }
      >
        {/* <Link to={`/paintings/${year}/${slug}`}> */}
        <LinkPainting to={`/painting/${slug}`} name={slug}>
          <Phone>
            <PaintImageFit
              width={sizes.width}
              height={sizes.height}
              offsetHeight={'150'}
              offsetWidth={'20%'}
            >
              <Img
                sizes={image}
                alt={''}
                // imgStyle={{ width: w, height: h }}
                style={{
                  // margin: '0 auto',
                  // width: w,
                  // height: '200px',
                  boxShadow:
                    no_frame_shadow === true ? null : '0px 1px 6px #616161',
                }}
              />
            </PaintImageFit>
          </Phone>
          <Tablet>{paintingImage}</Tablet>
          <Desktop>{paintingImage}</Desktop>
          <Default>{paintingImage}</Default>

          <PaintingTitle>{title}</PaintingTitle>
        </LinkPainting>
        <PaintingDescription>{composition}</PaintingDescription>
        <PaintingSizes>
          {sizes_description
            ? sizes_description
            : `${sizes.height}x${sizes.width}`}
        </PaintingSizes>
      </LazyLoad>
    </Container>
  )
}

export default Painting

export const PaintingCell = styled(Painting)`
  /* border: 1px solid red; */
  /* width: ${({ sizes }) => (sizes.width > 150 ? '100%' : '33.33%')}; */
  width: ${({ grid }) => getGridWidth(grid)};
  min-height: 400px;
  /* padding: 0px 0.5rem; */
  margin-bottom: 20px;

  /* ${media.tablet`
    width: ${({ sizes }) => (sizes.width > 150 ? '100%' : '50%')};
  `} */

  ${media.phone`
    width: 100%;
    min-height: auto;
  `}
`
