import React from 'react'

import { graphql, Link } from 'gatsby'
import withSizes from 'react-sizes'

import get from 'lodash/get'

import { media } from '../themes/index'

import Layout from '../components/layout'
import Painting, { PaintingCell } from '../components/Painting'

import styled from 'styled-components'

const PageContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 100px;

  ${media.phone`
    padding-top: 15px;
  `}
`

const PaintingsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

const YearTitle = styled.div`
  width: 50vw;
  margin: 0 auto 40px;
  padding: 0 0 32px;
  text-align: center;
  font-weight: 300;
  font-size: 4.5rem;
  line-height: 1;
  color: #ccc;
  border-bottom: 1px solid #eee;

  ${media.phone`
    width: 95%;

    font-size: 3rem;
    padding-bottom: 2px;
    margin-bottom: 10px;
    padding-top: 10px;
    border-top: 1px solid #eee;
    line-height: 100%;
  `}
`

const Paintings = ({ data, windowWidth }) => {
  const paintings = get(data, 'allSanityPaintings.edges', [])
  // console.log(paintings)
  // const years = groupBy(paintings, function({ node: { year } }) {
  //   return year
  // })

  const years = paintings.reduce((result, painting) => {
    const year = 'year-' + get(painting, 'node.year', 0)

    // result[year] = result[year]
    //   ? result[year].concat(result[year], [painting])
    //   : [painting]

    let yearList = result[year]

    if (yearList) {
      yearList.list = yearList.list.concat(painting)
    } else {
      yearList = {
        label: get(painting, 'node.year', 0),
        list: [painting],
      }
    }

    // const list = Array.isArray(result[year])
    //   ? result[year].concat(painting)
    //   : [painting]

    result[year] = yearList

    return result
  }, {})

  const yearKeys = Object.entries(years)

  return (
    <Layout>
      <PageContainer>
        {yearKeys.map(([year, paintingsOfYear]) => {
          // paintingsOfYear.list.sort(compare)

          return (
            <div key={year}>
              <YearTitle>{paintingsOfYear.label}</YearTitle>
              <PaintingsContainer>
                {paintingsOfYear.list

                  .reverse()
                  .map(
                    ({
                      node: {
                        id,
                        title,
                        mainImage,
                        composition,
                        no_frame_shadow,
                        sizes,
                        real_sizes,
                        slug,
                        year,
                        grid_position,
                        sizes_description,
                      },
                    }) => (
                      // <LazyLoad
                      //   key={id}
                      //   height={400}
                      //   once={true}
                      //   placeholder={
                      //     <PaintingPlaceholder
                      //       sizes={sizes}
                      //       grid={grid_position}
                      //     >
                      //       <Link
                      //         to={`/painting/${get(slug, 'current')}`}
                      //         name={get(slug, 'current')}
                      //       >
                      //         {title}
                      //       </Link>
                      //     </PaintingPlaceholder>
                      //   }
                      // >
                      <PaintingCell
                        windowWidth={windowWidth}
                        key={id}
                        id={id}
                        title={title}
                        year={year}
                        no_frame_shadow={no_frame_shadow}
                        slug={get(slug, 'current')}
                        image={get(mainImage, 'asset.fluid')}
                        composition={composition}
                        sizes={sizes}
                        sizes_description={sizes_description}
                        realSizes={real_sizes}
                        grid={grid_position}
                      />
                      // </LazyLoad>
                    )
                  )}
              </PaintingsContainer>
            </div>
          )
        })}
      </PageContainer>
    </Layout>
  )
}

const mapSizesToProps = ({ width, height }) => {
  return { windowWidth: width, windowHeight: height }
}

export default withSizes(mapSizesToProps)(Paintings)
// export default Paintings

export const query = graphql`
  query {
    allSanityPaintings(sort: { fields: [year, sorting], order: DESC }) {
      edges {
        node {
          id
          year
          title
          slug {
            current
          }
          sorting
          no_frame_shadow
          composition
          grid_position
          sizes {
            _type
            height
            width
          }
          real_sizes {
            _type
            height
            width
          }
          sizes_description
          mainImage {
            _type
            asset {
              fluid(maxWidth: 600) {
                ...GatsbySanityImageFluid
              }
            }
            # localFile {
            #   id
            #   absolutePath
            #   relativePath
            #   relativeDirectory
            #   sourceInstanceName
            #   childImageSharp {
            #     # Specify the image processing specifications right in the query.
            #     # Makes it trivial to update as your page's design changes.
            #     fixed(width: 125) {
            #       ...GatsbyImageSharpFixed
            #     }
            #     sizes(maxWidth: 600) {
            #       ...GatsbyImageSharpSizes
            #     }
            #   }
            # }
            # asset {
            #   metadata {
            #     palette {
            #       dominant {
            #         background
            #         foreground
            #         population
            #         title
            #       }
            #     }
            #     dimensions {
            #       aspectRatio
            #       height
            #       width
            #     }
            #   }
            # }
          }
        }
      }
    }
  }
`
