import React, { Component } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'

const ImageBox = styled.div`
  margin: 20px 0;
`
class PaintingImage extends Component {
  // constructor(props) {
  //   super(props)

  //   this.onHover = this.onHover.bind(this)
  // }

  state = {}

  render() {
    const { imageSizes, sizes, realSizes, alt = '', windowWidth } = this.props

    let sizeWidth = sizes.width
    let sizeHeight = sizes.height

    if (realSizes && realSizes.width && realSizes.height) {
      sizeWidth = realSizes.width
      sizeHeight = realSizes.height
    }

    var ratio = sizeWidth / sizeHeight

    //var h = Math.ceil( (400*a) / 200 );
    // console.log({ windowWidth })
    // var maxBaseWidth = 350 // Grid Sizes Cell
    var maxBaseWidth = windowWidth / 3 - 20 // Grid Sizes Cell
    var maxBaseCmWidth = 150 // The largest opera size in cm

    // 1000 : x = 1000 : width

    let w = (maxBaseWidth * sizeWidth) / maxBaseCmWidth
    let h = parseInt(w / ratio)

    // var h = parseInt( (400*a) / 200 );

    // if (this.state.isHover) {
    //   w = 'auto'
    //   h = 'auto'
    // }

    return (
      <ImageBox>
        <Img
          sizes={imageSizes}
          alt={alt}
          imgStyle={{ width: w, height: h }}
          style={{
            margin: '0 auto',
            width: w,
            height: h,
            boxShadow:
              this.props.no_frame_shadow === true
                ? null
                : '0px 1px 6px #616161',
          }}
        />
      </ImageBox>
    )
  }
}

export default PaintingImage

// export const query = graphql`
//   query($relativePath: String!) {
//     filename: file(relativePath: { eq: $relativePath }) {
//       childImageSharp {
//         fixed(width: 125, height: 125) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `
